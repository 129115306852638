import React from 'react';

import { footer } from './footer.module.scss';
import { keinoBusinessCard } from '../../config/business-cards';
import { breakpoints } from '../../config/breakpoints';
import useWindowWidth from '../../hooks/use-window-width';

import BusinessCard from './business-card';

interface IFooterTopProps {
    className?: string;
}

const FooterTop: React.FC<IFooterTopProps> = ({ className = '' }) => {
    const windowWidth = useWindowWidth() || breakpoints.phone;
    const isCentered = windowWidth < breakpoints.phone;
    return (
        <footer className={`${footer} ${className}`}>
            <BusinessCard
                businessCard={keinoBusinessCard}
                isCentered={isCentered}
                logoFileType="svg"
            />
        </footer>
    );
};

export default FooterTop;
