import React, { RefObject, useRef } from 'react';

import {
    container,
    enabled,
    xAxis,
    yAxis,
    innerContainer,
    eventsDisabled,
} from './scroll-container.module.scss';
import useScrollContainer from '../../hooks/use-scroll-container';

export interface IScrollContainerProps {
    className?: string;
    isDisabled?: boolean;
    axis?: 'x' | 'y';
    forwardRef?: RefObject<HTMLButtonElement>;
    backwardRef?: RefObject<HTMLButtonElement>;
    children?: React.ReactNode;
}

const ScrollContainer: React.FC<IScrollContainerProps> = ({
    className = '',
    isDisabled = false,
    axis = 'x',
    forwardRef,
    backwardRef,
    children,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { dragStart, dragStop, dragMove, wheelMove, dragging } = useScrollContainer({
        axis,
        containerRef,
        forwardRef,
        backwardRef,
    });

    return (
        <div
            ref={containerRef}
            className={`
                ${className} 
                ${container} 
                ${axis === 'x' ? xAxis : yAxis}
                ${isDisabled ? '' : enabled}
            `}
            {...(isDisabled
                ? {}
                : {
                      onWheel: wheelMove,
                      onMouseDown: dragStart,
                      onMouseUp: dragStop,
                      onMouseMove: dragMove,
                  })}
        >
            <div className={`${innerContainer} ${dragging ? eventsDisabled : ''}`}>{children}</div>
        </div>
    );
};

export default ScrollContainer;
