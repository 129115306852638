import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';
import { graphql, useStaticQuery } from 'gatsby';

import { container, linkLabel, dimLayer } from './contact-card.module.scss';
import { ISiteSettings } from '../../models/site-settings.model';
import { iDontWantOrphans } from '../../utils/i-dont-want-orphans';

interface IContactCardProps {
    className?: string;
}

interface IContactCardQueryResult {
    siteSettings: ISiteSettings | null;
}

const ContactCard: React.FC<IContactCardProps> = ({ className = '' }) => {
    const { t } = useI18next();
    const { siteSettings }: IContactCardQueryResult = useStaticQuery(query);
    const cardText = siteSettings?.contactText ? siteSettings.contactText : t('tile.contact');
    const cardTextWithoutOrphans = iDontWantOrphans(cardText, 3);
    return (
        <Link to="kontakt" className={`${container} ${className}`}>
            <div className={dimLayer} />
            <h2 className={linkLabel}>{cardTextWithoutOrphans}</h2>
        </Link>
    );
};

const query = graphql`
    query {
        siteSettings {
            contactText
        }
    }
`;

export default ContactCard;
