import React, { useEffect, useRef, useState } from 'react';
import { useIsInViewport } from '../../hooks/use-is-in-viewport';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';

import {
    container,
    contentBox,
    titleText,
    tagName,
    dimLayer,
    imageBox,
    ratio,
    canBlur,
    background,
} from './post-card.module.scss';
import { IPostCard } from '../../models/post.model';
import { TGridItemOrientation } from '../../models/grid-item.model';
import { translationKeys } from '../../config/translation-keys';
import { relations } from '../../config/relations';
import { useTranslation } from '../../hooks/use-translation';
import useMedia from '../../hooks/use-media';

import RatioImage from '../atoms/ratio-image';

interface IPostCardProps {
    className?: string;
    TitleElement?: React.ElementType;
    TagElement?: React.ElementType;
    postCard: IPostCard;
    coverVersion?: TGridItemOrientation;
}

const PostCard: React.FC<IPostCardProps> = ({
    className = '',
    TitleElement = 'h2',
    TagElement = 'h3',
    postCard,
    coverVersion = 'square',
}) => {
    const { slug, media, tags, tagsPriority } = postCard;

    const [loadImage, setLoadImage] = useState(false);
    const linkRef = useRef<HTMLAnchorElement>(null);
    const isInViewport = useIsInViewport(linkRef);

    const mainTagId = tagsPriority.find((tagPriority) => tagPriority.isMain)?.tagId;
    const mainTag = tags.find((tag) => tag.tagId === mainTagId);

    const covers: Record<TGridItemOrientation, ReturnType<typeof useMedia>> = {
        square: useMedia({
            media,
            relation: relations.coverImage,
        }),
        horizontal: useMedia({
            media,
            relation: relations.coverImageHorizontal,
            fallback: relations.coverImage,
        }),
        vertical: useMedia({
            media,
            relation: relations.coverImageVertical,
            fallback: relations.coverImage,
        }),
    };
    const { titleWithoutOrphans } = useTranslation(postCard, translationKeys.postCard);
    const { name } = useTranslation(mainTag, translationKeys.tag);

    useEffect(() => {
        setLoadImage(isInViewport);
    }, [isInViewport]);

    // Images are duplicated, one is for the blur effect
    // The next one acts as a background and doesn't blur on hover
    // This is necessary because the client wants 'sharp edges' on the image when it's blurred
    // and no other hacks worked
    // Can be replaced with backdrop-filter when this CSS property will work in Firefox
    return (
        <Link innerRef={linkRef} className={`${container} ${className}`} to={slug}>
            {loadImage && (
                <>
                    <RatioImage
                        image={covers[coverVersion].image}
                        isOrdinaryImg={true}
                        ratioClass={ratio}
                        className={`${imageBox} ${background}`}
                    />
                    <RatioImage
                        image={covers[coverVersion].image}
                        alt={covers[coverVersion].alt}
                        ratioClass={ratio}
                        className={`${imageBox} ${canBlur}`}
                    />
                </>
            )}
            <span className={dimLayer} />
            <div className={contentBox}>
                <TitleElement className={titleText}>{titleWithoutOrphans}</TitleElement>
                {mainTag && <TagElement className={tagName}>{name}</TagElement>}
            </div>
        </Link>
    );
};

export default PostCard;
