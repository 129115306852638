import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    content,
    ratio,
    imageBox,
    introLabel,
    dimLayer,
    iconBox,
    icon,
    canBlur,
    background,
} from './intro-card.module.scss';
import { ImageDataLike } from '../../models/image-data-like';
import { useIntroContext } from '../../contexts/intro-context';

import IconButton from '../atoms/icon-button';
import RatioImage from '../atoms/ratio-image';

interface IIntroCardProps {
    className?: string;
}

interface IIntroCardQueryResult {
    coverImage: ImageDataLike;
}

const IntroCard: React.FC<IIntroCardProps> = ({ className = '' }) => {
    const { t } = useI18next();
    const { showIntro } = useIntroContext();
    const { coverImage }: IIntroCardQueryResult = useStaticQuery(query);

    const image = getImage(coverImage);

    const handleClick = () => {
        showIntro();
    };

    // Images are duplicated, one is for the blur effect
    // The next one acts as a background and doesn't blur on hover
    // This is necessary because the client wants 'sharp edges' on the image when it's blurred
    // and no other hacks worked
    // Can be replaced with backdrop-filter when this CSS property will work in Firefox
    return (
        <button className={`${container} ${className}`} onClick={handleClick}>
            <RatioImage
                image={image}
                isOrdinaryImg={true}
                ratioClass={ratio}
                className={`${imageBox} ${background}`}
            />
            <RatioImage image={image} ratioClass={ratio} className={`${imageBox} ${canBlur}`} />
            <span className={dimLayer} />
            <div className={content}>
                <IconButton
                    as="element"
                    icon="play"
                    color="light"
                    className={iconBox}
                    iconClassName={icon}
                />
                <h2 className={introLabel}>{t('tile.intro')}</h2>
            </div>
        </button>
    );
};

const query = graphql`
    query {
        coverImage: file(relativePath: { eq: "intro-cover.jpg" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
    }
`;

export default IntroCard;
