import { RefObject, useEffect, useState } from 'react';

export const useIsInViewport = <Element extends HTMLElement>(ref: RefObject<Element>) => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    useEffect(() => {
        if (!ref.current) return;

        const observer = new IntersectionObserver(([entry]) => {
            setIsIntersecting((prev) => {
                if (prev) return prev;
                return entry.isIntersecting;
            });
        });

        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref]);

    return isIntersecting;
};
