export function iDontWantOrphans(text: string, maxOrphanLength: number = 2): string {
    const strArr = text.split(' ');
    return strArr.reduce((newText, wordItem, index) => {
        // first type of dash (charCode 8211) doesn't utilize nbsp as expected
        // that's why we are replacing it with another type of dash (charCode 45)
        const word = wordItem === '–' ? '-' : wordItem;
        if (index === strArr.length - 1) {
            return `${newText}${word}`;
        }
        return `${newText}${word.length <= maxOrphanLength ? `${word}\xa0` : `${word} `}`;
    }, '');
}
