import { RefObject } from 'react';

import { footerGridTemplate } from '../config/footer-grid-template';
import { IGridTemplate, IGridTemplatePosition, TOrientation } from '../models/grid-template.model';
import { TGridItemType } from '../models/grid-item.model';
import { IPostCard } from '../models/post.model';
import { ISlogan } from '../models/slogan.model';
import { IGridItem } from '../models/grid-item.model';
import { getRandomInteger } from './get-random-integer';
import { getActiveBreakpoint } from './get-active-breakpoint';
import { isBrowser } from './is-browser';

const CONTACT_CARD_INTERVAL = 3;

export interface IGridItems {
    [key: string]: IGridItem;
}

export interface IGetGridPositionsConfig {
    postCards: IPostCard[];
    slogans: ISlogan[];
    templates: IGridTemplate[];
    footerTemplate: IGridTemplate;
    containerRef: RefObject<HTMLDivElement>;
    sizeContainerRef: RefObject<HTMLDivElement>;
    gap?: number;
    includeIntro?: boolean;
}

export function getGridItems({
    containerRef,
    sizeContainerRef,
    gap = 0,
    postCards,
    templates,
    includeIntro = false,
}: IGetGridPositionsConfig): IGridItems {
    if (!containerRef.current || !sizeContainerRef.current || !templates.length) return {};

    const positions: IGridItems = {};

    let templateState = getTemplateState({
        templates,
        gap,
        sizeContainer: sizeContainerRef.current,
    });

    let gridCount = 0;
    let gridMap: TGridMap = [];
    let prevGridsUnitCount = 0;
    let activeGridUnitCount = 0;

    let contactIndexId = 0;
    let sloganIndexId = 0;
    const blankIndexId = 0;
    let introIncluded = false;
    let includeContact = true;
    let contactIncluded = false;

    let positionIndex = 0;
    const tempPosts = [...postCards];
    while (tempPosts.length) {
        const post = tempPosts[0];
        const { unit, template, orientation } = templateState;

        const position = getPosition(template, positionIndex);
        const style: IGridItem['style'] = getGridItemStyle({
            position,
            gap,
            orientation,
            unit,
            prevGridsUnitCount,
        });
        const type = getGridItemType({
            position,
            includeIntro,
            introIncluded,
            includeContact,
            contactIncluded,
            postAvailable: !!post,
        });
        const itemOrientation = getGridItemOrientation(position);
        const key = getGridItemKey({
            type,
            post,
            blankIndexId,
            contactIndexId,
            sloganIndexId,
        });

        const newGridItem: IGridItem = {
            style,
            type,
            itemOrientation,
            key,
        };

        positions[newGridItem.key] = newGridItem;

        if (newGridItem.type === 'intro') {
            introIncluded = true;
        }
        if (newGridItem.type === 'contact') {
            contactIncluded = true;
            contactIndexId++;
        }
        if (newGridItem.type === 'slogan') {
            sloganIndexId++;
        }
        if (newGridItem.type === 'post') {
            tempPosts.shift();
        }

        const positionUnitCount = getPositionUnitCount(position, orientation);
        if (positionUnitCount > activeGridUnitCount) {
            activeGridUnitCount = positionUnitCount;
        }

        gridMap = updateGridMap(gridMap, position, orientation, prevGridsUnitCount);

        positionIndex++;
        if (!template.positions[positionIndex]) {
            positionIndex = 0;
            prevGridsUnitCount = prevGridsUnitCount + activeGridUnitCount;
            activeGridUnitCount = 0;
            gridCount++;
            includeContact = gridCount % CONTACT_CARD_INTERVAL === 0;
            contactIncluded = false;
            templateState = getTemplateState({
                templates,
                gap,
                prevState: templateState,
                sizeContainer: sizeContainerRef.current,
            });
        }
    }

    const blankItems = getBlankGridItems({
        gridMap,
        gap,
        templateState,
        prevGridsUnitCount,
    });
    blankItems.forEach((item) => {
        positions[item.key] = item;
    });

    const footerGrid = getFooterGrid({
        footerTemplate: footerGridTemplate,
        gap,
        prevGridsUnitCount: prevGridsUnitCount + activeGridUnitCount,
        templateState,
    });
    footerGrid.items.forEach((item) => {
        positions[item.key] = item;
    });

    const totalUnitCount = footerGrid.unitsCount + prevGridsUnitCount + activeGridUnitCount;
    const totalSize = totalUnitCount * templateState.unit + (totalUnitCount - 1) * gap;

    if (templateState.orientation === 'horizontal') {
        containerRef.current.style.width = `${totalSize}px`;
        containerRef.current.style.height = '';
    } else {
        containerRef.current.style.width = '';
        containerRef.current.style.height = `${totalSize}px`;
    }

    return positions;
}

interface IGetFooterGridConfig {
    footerTemplate: IGridTemplate;
    templateState: IGetTemplateStateReturn;
    gap: number;
    prevGridsUnitCount: number;
}

function getFooterGrid({
    footerTemplate,
    templateState,
    gap,
    prevGridsUnitCount,
}: IGetFooterGridConfig): { items: IGridItem[]; unitsCount: number } {
    const { orientation, unit } = templateState;
    const footerItems: IGridItem[] = [];
    let footerUnitsCount = 0;
    footerTemplate.positions.forEach((position, index) => {
        const itemPosition = getPosition(footerTemplate, index);
        const positionUnitCount = getPositionUnitCount(itemPosition, orientation);
        if (positionUnitCount > footerUnitsCount) {
            footerUnitsCount = positionUnitCount;
        }
        footerItems.push({
            key: itemPosition.allowed[0],
            type: itemPosition.allowed[0],
            itemOrientation: getGridItemOrientation(itemPosition),
            style: getGridItemStyle({
                position: itemPosition,
                gap,
                orientation,
                unit,
                prevGridsUnitCount,
            }),
        });
    });
    return {
        items: footerItems,
        unitsCount: footerUnitsCount,
    };
}

interface IGetBlankGidItemsConfig {
    gridMap: TGridMap;
    templateState: IGetTemplateStateReturn;
    gap: number;
    prevGridsUnitCount: number;
}

function getBlankGridItems({
    gridMap,
    templateState,
    gap,
    prevGridsUnitCount,
}: IGetBlankGidItemsConfig): IGridItem[] {
    const { orientation, unit } = templateState;
    const blanks: IGridItem[] = [];

    const maxOrientationLength = Math.max(...gridMap.map((itemsMapArr) => itemsMapArr.length));
    const areBlanksNeeded = gridMap.some(
        (itemsMapArr) => itemsMapArr.length !== maxOrientationLength
    );

    if (areBlanksNeeded) {
        let blankId = 0;
        gridMap.forEach((itemsMapArr, outerIndex) => {
            if (itemsMapArr.length === maxOrientationLength) return;
            const blanksNeededCount = maxOrientationLength - itemsMapArr.length;
            for (let i = 0; i < blanksNeededCount; i++) {
                const reservedUnitsCount = itemsMapArr.length - prevGridsUnitCount;
                const row =
                    orientation === 'horizontal' ? outerIndex + 1 : reservedUnitsCount + i + 1;
                const column =
                    orientation === 'horizontal' ? reservedUnitsCount + i + 1 : outerIndex + 1;
                const position: IGridTemplatePosition = {
                    allowed: ['blank'],
                    row,
                    column,
                    widthUnits: 1,
                    heightUnits: 1,
                };
                blanks.push({
                    type: 'blank',
                    key: `blank-${blankId}`,
                    itemOrientation: 'square',
                    style: getGridItemStyle({
                        position,
                        gap,
                        orientation,
                        unit,
                        prevGridsUnitCount,
                    }),
                });
                blankId++;
            }
        });
    }
    return blanks;
}

type TGridMap = Array<Array<true>>;
function updateGridMap(
    gridMap: TGridMap | [],
    position: IGridTemplatePosition,
    orientation: TOrientation,
    prevGridsUnitCount: number
): TGridMap {
    const { column, row, widthUnits, heightUnits } = position;

    const rowStartIndex = row - 1;
    const rowEndIndex = row - 1 + heightUnits - 1;
    const columnStartIndex = column - 1;
    const columnEndIndex = column - 1 + widthUnits - 1;

    const outerStart = orientation === 'horizontal' ? rowStartIndex : columnStartIndex;
    const outerEnd = orientation === 'horizontal' ? rowEndIndex : columnEndIndex;
    const innerStart = orientation === 'horizontal' ? columnStartIndex : rowStartIndex;
    const innerEnd = orientation === 'horizontal' ? columnEndIndex : rowEndIndex;

    for (let i = outerStart; i <= outerEnd; i++) {
        for (let j = innerStart + prevGridsUnitCount; j <= innerEnd + prevGridsUnitCount; j++) {
            if (!gridMap[i]) {
                gridMap[i] = [];
            }
            gridMap[i][j] = true;
        }
    }

    return gridMap;
}

function getPositionUnitCount(position: IGridTemplatePosition, orientation: TOrientation) {
    const { heightUnits, widthUnits, row, column } = position;
    return orientation === 'horizontal' ? column + widthUnits - 1 : row + heightUnits - 1;
}

interface IGetGridItemKeyConfig {
    type: TGridItemType;
    post?: IPostCard;
    contactIndexId: number;
    sloganIndexId: number;
    blankIndexId: number;
}

function getGridItemKey({
    type,
    post,
    contactIndexId,
    sloganIndexId,
    blankIndexId,
}: IGetGridItemKeyConfig): string {
    if (type === 'post') return `${type}-${post?.articleId}`;
    if (type === 'slogan') return `${type}-${sloganIndexId}`;
    if (type === 'contact') return `${type}-${contactIndexId}`;
    if (type === 'blank') return `${type}-${blankIndexId}`;
    return `${type}`;
}

interface IGetGridItemTypeConfig {
    position: IGridTemplatePosition;
    includeIntro: boolean;
    introIncluded: boolean;
    includeContact: boolean;
    contactIncluded: boolean;
    postAvailable: boolean;
}

function getGridItemType({
    position,
    includeIntro,
    introIncluded,
    includeContact,
    contactIncluded,
    postAvailable,
}: IGetGridItemTypeConfig): IGridItem['type'] {
    if (isIntro({ position, includeIntro, introIncluded })) return 'intro';
    if (isContact({ position, includeContact, contactIncluded })) return 'contact';
    if (isSlogan({ position })) return 'slogan';
    if (isPost({ position, postAvailable })) return 'post';
    return 'blank';
}

interface IGetGridItemStyleConfig {
    position: IGridTemplatePosition;
    gap: number;
    orientation: TOrientation;
    unit: number;
    prevGridsUnitCount: number;
}

function getGridItemStyle({
    position,
    gap,
    orientation,
    unit,
    prevGridsUnitCount,
}: IGetGridItemStyleConfig): IGridItem['style'] {
    const { column, row, widthUnits, heightUnits } = position;
    const prevGridShift = prevGridsUnitCount * unit + prevGridsUnitCount * gap;
    const horizontalShift = orientation === 'horizontal' ? prevGridShift : 0;
    const verticalShift = orientation === 'vertical' ? prevGridShift : 0;
    return {
        position: 'absolute',
        width: `${widthUnits * unit + (widthUnits - 1) * gap}px`,
        height: `${heightUnits * unit + (heightUnits - 1) * gap}px`,
        top: 0,
        left: 0,
        transform: `translate(${(column - 1) * unit + (column - 1) * gap + horizontalShift}px, ${
            (row - 1) * unit + (row - 1) * gap + verticalShift
        }px)`,
    };
}

function getPosition(template: IGridTemplate, positionIndex: number): IGridTemplatePosition {
    const templatePosition = template.positions[positionIndex];
    const breakpoint = getActiveBreakpointFromObject(templatePosition);
    let position = templatePosition;
    if (breakpoint && templatePosition.breakpoints) {
        position = {
            ...templatePosition.breakpoints[breakpoint],
            allowed: templatePosition.allowed,
        };
    }
    return position;
}

interface IGetTemplateStateConfig {
    templates: IGridTemplate[];
    prevState?: IGetTemplateStateReturn;
    gap: number;
    sizeContainer: HTMLDivElement;
}

interface IGetTemplateStateReturn {
    template: IGridTemplate;
    orientation: TOrientation;
    unit: number;
    units: number;
}

function getTemplateState({
    templates,
    prevState,
    gap,
    sizeContainer,
}: IGetTemplateStateConfig): IGetTemplateStateReturn {
    const excludeTemplateId =
        templates.length > 1 && prevState ? prevState.template.gridId : undefined;
    const template = getRandomTemplate({
        templates,
        onlyWithMainItem: !prevState,
        excludeId: excludeTemplateId,
    });
    const templateBreakpoint = getActiveBreakpointFromObject(template);
    const orientation = getTemplateOrientation(template, templateBreakpoint);
    const space = getGridSpace(sizeContainer, orientation);
    const units = getGridUnits(template, templateBreakpoint);
    const unit = getGridUnitSize({ units, space, gap });

    return {
        template,
        orientation,
        unit,
        units,
    };
}

function getGridItemOrientation(position: IGridTemplatePosition): IGridItem['itemOrientation'] {
    const { widthUnits, heightUnits } = position;
    if (widthUnits > heightUnits) return 'horizontal';
    if (widthUnits < heightUnits) return 'vertical';
    return 'square';
}

function getGridUnits(template: IGridTemplate, breakpoint?: number): number {
    let units = template.units;
    if (template.breakpoints && breakpoint) {
        units = template.breakpoints[breakpoint].units;
    }
    return units;
}

interface IGetGridUnitSizeConfig {
    units: number;
    space: number;
    gap: number;
}

function getGridUnitSize({ units, space, gap }: IGetGridUnitSizeConfig): number {
    return (space - (units - 1) * gap) / units;
}

function getGridSpace(container: HTMLDivElement, orientation: TOrientation): number {
    return container.getBoundingClientRect()[orientation === 'horizontal' ? 'height' : 'width'];
}

function getTemplateOrientation(template: IGridTemplate, breakpoint?: number): TOrientation {
    if (template.breakpoints && breakpoint) {
        return template.breakpoints[breakpoint].orientation;
    }
    return template.orientation;
}

function getActiveBreakpointFromObject<T extends { breakpoints?: { [key: number]: any } }>(
    template: T
): number | undefined {
    const { breakpoints } = template;
    if (isBrowser() && breakpoints) {
        const breakpointKeys = Object.keys(breakpoints).map((bp) => Number(bp)) || [];
        return getActiveBreakpoint(breakpointKeys, window.innerWidth);
    }
}

interface ICheckItemType {
    position: IGridTemplatePosition;
}

interface IIsIntroConfig extends ICheckItemType {
    includeIntro: boolean;
    introIncluded: boolean;
}

function isIntro({ position, includeIntro, introIncluded }: IIsIntroConfig): boolean {
    return position.allowed.includes('intro') && includeIntro && !introIncluded;
}

interface IIsContactConfig extends ICheckItemType {
    includeContact: boolean;
    contactIncluded: boolean;
}

function isContact({ position, includeContact, contactIncluded }: IIsContactConfig): boolean {
    return position.allowed.includes('contact') && includeContact && !contactIncluded;
}

function isSlogan({ position }: ICheckItemType): boolean {
    return position.allowed.includes('slogan');
}

interface IIsPostConfig extends ICheckItemType {
    postAvailable: boolean;
}

function isPost({ position, postAvailable }: IIsPostConfig): boolean {
    return position.allowed.includes('post') && postAvailable;
}

interface IGetRandomTemplateConfig {
    templates: IGridTemplate[];
    excludeId?: IGridTemplate['gridId'];
    onlyWithMainItem?: boolean;
}

function getRandomTemplate({
    templates = [],
    excludeId,
    onlyWithMainItem = false,
}: IGetRandomTemplateConfig): IGridTemplate {
    const validTemplates = templates.filter((template) => {
        const isExcluded = template.gridId === excludeId;
        if (isExcluded) return false;
        return onlyWithMainItem ? template.hasMainItem : !!template;
    });
    return validTemplates[getRandomInteger(0, validTemplates.length - 1)];
}
