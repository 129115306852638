import React from 'react';

import { container, centered, emailLink, phoneLink, imageBox } from './business-card.module.scss';
import { translationKeys } from '../../config/translation-keys';
import { IBusinessCard } from '../../models/business-card.model';
import { useTranslation } from '../../hooks/use-translation';

import Logo, { ILogoProps } from '../atoms/logo';

interface IBusinessCardProps {
    className?: string;
    businessCard: IBusinessCard;
    isCentered?: boolean;
    logoFileType?: ILogoProps['fileType'];
}

const BusinessCard: React.FC<IBusinessCardProps> = ({
    className = '',
    businessCard,
    isCentered = false,
    logoFileType,
}) => {
    const { groupMember } = businessCard;

    const {
        name,
        officeEmail,
        nip,
        street = '',
        streetNumber = '',
        zipCode = '',
        city = '',
        phone = '',
        phoneLabel = '',
    } = useTranslation(businessCard, translationKeys.businessCard);

    return (
        <address className={`${container} ${className} ${isCentered ? centered : ''}`}>
            <Logo
                className={imageBox}
                objectPosition={isCentered ? 'center' : 'left'}
                groupMember={groupMember}
                fileType={logoFileType}
            />
            <div>
                <a href={`mailto:${officeEmail}`} className={emailLink}>
                    {officeEmail}
                </a>
            </div>
            <div>
                <p>{name}</p>
                <p>NIP {nip}</p>
            </div>
            {(street || zipCode || city) && (
                <div>
                    <p>
                        {street} {streetNumber}
                    </p>
                    <p>
                        {zipCode} {city}
                    </p>
                </div>
            )}
            {phone && phoneLabel && (
                <div>
                    <a href={`tel:${phone}`} className={phoneLink}>
                        {phoneLabel}
                    </a>
                </div>
            )}
        </address>
    );
};

export default BusinessCard;
