import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { graphql } from 'gatsby';

import { container, sizeContainer, gridContainer, seoBuildList } from './grid-page.module.scss';
import { breakpoints } from '../config/breakpoints';
import { translationKeys } from '../config/translation-keys';
import { gridTemplates } from '../config/grid-templates';
import { footerGridTemplate } from '../config/footer-grid-template';
import { ISitePageContext } from '../models/site-page.model';
import { ISlogan } from '../models/slogan.model';
import { getRandomItemsFromArray } from '../utils/get-random-array';
import { getGridItems, IGridItems } from '../utils/get-grid-items';
import { usePostCards } from '../contexts/post-cards-context';
import { useSlogans } from '../contexts/slogans-context';
import { useTranslation } from '../hooks/use-translation';
import useWindowWidth from '../hooks/use-window-width';
import useTagFromUrlParams from '../hooks/use-tag-from-url-params';

import ScrollContainer from '../components/hoc/scroll-container';
import MainLayout from '../layouts/main-layout';
import PostCard from '../components/molecules/post-card';
import ContactCard from '../components/molecules/contact-card';
import GridItem from '../components/molecules/grid-item';
import IntroCard from '../components/molecules/intro-card';
import SloganCard from '../components/molecules/slogan-card';
import BlankCard from '../components/molecules/blank-card';
import FooterTop from '../components/molecules/footer-top';
import FooterBottom from '../components/molecules/footer-bottom';

interface ITileGridPageProps {
    readonly pageContext: ISitePageContext;
}

const GridPage: React.FC<ITileGridPageProps> = ({ pageContext }) => {
    const tag = useTagFromUrlParams(true);

    const { name } = useTranslation(tag, translationKeys.tag);
    const { label } = useTranslation(pageContext, translationKeys.pageContext);
    const seoTitle = name || label;

    const postCards = usePostCards({ tagId: tag?.tagId });
    const slogans = useSlogans();
    const windowWidth = useWindowWidth();
    const [isScrollDisabled, setIsScrollDisabled] = useState<boolean>(
        isBreakpointReached(windowWidth, breakpoints.iPad)
    );
    const [gridItems, setGridItems] = useState<IGridItems | null>(null);

    const gridContainerRef = useRef<HTMLDivElement>(null);
    const sizeContainerRef = useRef<HTMLDivElement>(null);
    const slogansRef = useRef<ISlogan[]>(getRandomItemsFromArray(slogans, 10));

    useLayoutEffect(() => {
        if (!gridContainerRef.current) return;
        setGridItems(
            getGridItems({
                templates: gridTemplates,
                footerTemplate: footerGridTemplate,
                postCards: postCards,
                containerRef: gridContainerRef,
                sizeContainerRef: sizeContainerRef,
                includeIntro: !tag,
                gap: 6,
                slogans: slogans,
            })
        );
    }, [windowWidth, postCards, tag, slogans]);

    useEffect(() => {
        setIsScrollDisabled(isBreakpointReached(windowWidth, breakpoints.iPad));
    }, [windowWidth]);

    useEffect(() => {
        window.scroll(0, 0);
    }, [tag]);

    return (
        <MainLayout SEOProps={{ title: seoTitle }} isHeightAsViewport={true} isFooter={false}>
            <ScrollContainer className={container} isDisabled={isScrollDisabled}>
                <div className={sizeContainer} ref={sizeContainerRef}>
                    <div className={gridContainer} ref={gridContainerRef}>
                        {gridItems ? (
                            <>
                                {postCards.map((postCard) => {
                                    const key = `post-${postCard.articleId}`;
                                    return (
                                        <GridItem key={key} gridItem={gridItems[key]}>
                                            <PostCard
                                                postCard={postCard}
                                                coverVersion={gridItems[key]?.itemOrientation}
                                            />
                                        </GridItem>
                                    );
                                })}
                                <GridItem
                                    gridItem={{
                                        ...gridItems.intro,
                                        type: 'intro',
                                    }}
                                >
                                    <IntroCard key="intro" />
                                </GridItem>
                                {Object.keys(gridItems).map((key) => {
                                    if (key.includes('contact')) {
                                        return (
                                            <GridItem key={key} gridItem={gridItems[key]}>
                                                <ContactCard />
                                            </GridItem>
                                        );
                                    }
                                    return null;
                                })}
                                {slogans.length > 0 &&
                                    Object.keys(gridItems).map((key) => {
                                        if (key.includes('slogan')) {
                                            const sloganIndex = Number(key.split('-')[1]);
                                            return (
                                                <GridItem key={key} gridItem={gridItems[key]}>
                                                    <SloganCard
                                                        slogan={slogansRef.current[sloganIndex]}
                                                    />
                                                </GridItem>
                                            );
                                        }
                                        return null;
                                    })}
                                {Object.keys(gridItems).map((key) => {
                                    if (key.includes('blank')) {
                                        return (
                                            <GridItem key={key} gridItem={gridItems[key]}>
                                                <BlankCard />
                                            </GridItem>
                                        );
                                    }
                                    return null;
                                })}
                                <GridItem gridItem={gridItems.footerTop}>
                                    <FooterTop key="footerTop" />
                                </GridItem>
                                <GridItem gridItem={gridItems.footerBottom}>
                                    <FooterBottom key="footerBottom" />
                                </GridItem>
                            </>
                        ) : (
                            <ul className={seoBuildList}>
                                {postCards.map((postCard) => {
                                    return (
                                        <li key={postCard.articleId}>
                                            <PostCard postCard={postCard} />
                                        </li>
                                    );
                                })}
                                <li key={`contact-card`}>
                                    <ContactCard />
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </ScrollContainer>
        </MainLayout>
    );
};

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

function isBreakpointReached(windowWidth: number | undefined, breakpoint: number): boolean {
    return (windowWidth && windowWidth < breakpoint) || false;
}

export default GridPage;
