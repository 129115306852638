import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from '@reach/router';
import { useIsInViewport } from '../../hooks/use-is-in-viewport';
import { Textfit } from 'react-textfit';

import { outerContainer, container } from './slogan-card.module.scss';
import { ISlogan } from '../../models/slogan.model';

interface ISloganCardProps {
    className?: string;
    slogan: ISlogan;
}

const SloganCard: React.FC<ISloganCardProps> = ({ className = '', slogan }) => {
    const { contentWithoutOrphans } = slogan;
    const { search } = useLocation();
    const [isReady, setIsReady] = useState(false);
    const [forceRenderId, setForceRenderId] = useState(0);
    const sloganRef = useRef<HTMLDivElement>(null);
    const isInViewport = useIsInViewport(sloganRef);

    // 🚨 HACK ALERT! 🚨
    // To fit font size properly by Textfit useLayoutEffect with setTimeout was needed!
    // If anyone has a different idea - feel free to refactor
    useLayoutEffect(() => {
        const timeout = setTimeout(() => {
            setIsReady(true);
        }, 0);
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setForceRenderId((prevState) => prevState + 1);
        }, 500);
        return () => clearTimeout(timeout);
    }, [search]);

    return (
        <div className={outerContainer} ref={sloganRef}>
            {isInViewport && isReady && (
                <Textfit
                    key={forceRenderId}
                    mode="multi"
                    max={200}
                    className={`${container} ${className}`}
                >
                    {contentWithoutOrphans}
                </Textfit>
            )}
        </div>
    );
};

export default SloganCard;
