import { useRef, useState, MouseEvent, WheelEvent, useCallback, RefObject, useEffect } from 'react';
import { useLocation } from '@reach/router';

interface IUseScrollContainerConfig {
    containerRef: RefObject<HTMLElement>;
    axis?: 'x' | 'y';
    forwardRef?: RefObject<HTMLButtonElement>;
    backwardRef?: RefObject<HTMLButtonElement>;
}

const SCROLL_RESET_RESOLUTION = 50;
const SCROLL_RESET_INTERVAL = 2;

const useScrollContainer = ({
    containerRef,
    axis = 'x',
    forwardRef,
    backwardRef,
}: IUseScrollContainerConfig) => {
    const { search } = useLocation();
    const clientAxis = axis === 'x' ? 'clientX' : 'clientY';
    const scrollAxis = axis === 'x' ? 'scrollLeft' : 'scrollTop';
    const containerAxis = axis === 'x' ? 'offsetWidth' : 'offsetHeight';
    const [clicked, setClicked] = useState<boolean>(false);
    const [dragging, setDragging] = useState<boolean>(false);
    const [backwardHasEvent, setBackwardHasEvent] = useState<boolean>(false);
    const [forwardHasEvent, setForwardHasEvent] = useState<boolean>(false);
    const position = useRef(0);

    const dragStart = useCallback((event: MouseEvent) => {
        position.current = event[clientAxis];
        setClicked(true);
    }, []);

    const dragStop = useCallback(() => {
        window.requestAnimationFrame(() => {
            setDragging(false);
            setClicked(false);
        });
    }, []);

    const dragMove = (event: MouseEvent) => {
        const newDiff = position.current - event[clientAxis];
        const movedEnough = Math.abs(newDiff) > 5;

        if (clicked && movedEnough) {
            setDragging(true);
        }

        if (dragging && movedEnough && containerRef.current) {
            position.current = event[clientAxis];
            containerRef.current[scrollAxis] += newDiff;
        }
    };

    const wheelMove = (event: WheelEvent) => {
        if (!containerRef.current) return;
        const scrollMove = event.deltaY;
        containerRef.current[scrollAxis] += scrollMove;
    };

    useEffect(() => {
        const scrollForward = () => {
            if (!containerRef.current) return;
            const containerSize = containerRef.current[containerAxis];
            containerRef.current[scrollAxis] += containerSize * 0.8;
        };

        if (!forwardRef?.current || forwardHasEvent) return;
        setForwardHasEvent(true);
        forwardRef.current.addEventListener('click', scrollForward);
    }, [forwardRef?.current, forwardHasEvent]);

    useEffect(() => {
        const scrollBackward = () => {
            if (!containerRef.current) return;
            const containerSize = containerRef.current[containerAxis];
            containerRef.current[scrollAxis] -= containerSize * 0.8;
        };

        if (!backwardRef?.current || backwardHasEvent) return;
        setBackwardHasEvent(true);
        backwardRef.current.addEventListener('click', scrollBackward);
    }, [backwardRef?.current, backwardHasEvent]);

    useEffect(() => {
        if (!containerRef.current) return;
        const interval = setInterval(() => {
            if (!containerRef.current || containerRef.current[scrollAxis] === 0) {
                clearInterval(interval);
                return;
            }
            containerRef.current[scrollAxis] =
                containerRef.current[scrollAxis] - SCROLL_RESET_RESOLUTION;
        }, SCROLL_RESET_INTERVAL);
        return () => clearInterval(interval);
    }, [search]);

    return {
        dragStart,
        dragStop,
        dragMove,
        dragging,
        position,
        wheelMove,
        setDragging,
    };
};

export default useScrollContainer;
